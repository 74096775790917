.container {
    width: 100%;
    font-size: 1rem; /*default for button*/
    height: 3rem;
    display: flex;
    justify-content: center;

}

.button {
    position: relative;
    height: 100%;
    border-radius: 1.5rem;
    width: 100%;
    border: none;
    outline: none !important;
    -webkit-tap-highlight-color: transparent !important;
    color: white;
    font-weight: 700;
    user-select: none;
    transition-duration: .2s;
    min-width: 80px;

    /*background-color: #1596C0;*/
}

.button div, .button span, .button {
    text-overflow: ellipsis !important;
    white-space: nowrap;
    overflow: hidden;
}

.withShadow:hover{
    -webkit-box-shadow: inset 0px -1px 36px -14px rgba(0,0,0,0.33);
    -moz-box-shadow: inset 0px -1px 36px -14px rgba(0,0,0,0.33);
    box-shadow: inset 0px -1px 36px -14px rgba(0,0,0,0.33);
}
