.container {
     position: relative;
     margin-bottom: 0.7rem;
}

.input {
     width: 100%;
     padding: 0 1.5rem;
     font-size: 0.9rem;
     border: 1px solid #eeeeee;
     background-color: #F8F8F8;
     color: #5C5C5F;
     /*margin-bottom: 0.7rem;*/
     display: flex;
     align-items: center;
     line-height: 1;
 }


.selectInput {
     outline: none;
     width: 100%;
     border-radius: 1rem;
     padding: 0 1.5rem;
     font-size: 0.9rem;
     border: 1px solid #eeeeee;
     background-color: #F8F8F8;
     color: #5C5C5F;
     /*margin-bottom: 0.7rem;*/
     -webkit-appearance:none;
     -moz-appearance:none;
     appearance:none;
}

.required {
     position: absolute;
     right: -0.5rem;
     top: -0.5rem;
     color: #aa2d0d;
}
