.editor {
    border-radius: 1rem;
    max-width: calc(21cm + 2px);
}

.editorContainer {
    overflow-y: scroll;
    width: 100vw;
}
.header {
    display: flex;
    align-items: center;
    border-radius: 0 0 .5rem .5rem;
    border-bottom: solid 2px #ececec;
    overflow: hidden;
    justify-content: space-between;
    padding: 0 1rem;
}

.more {
    color: #0f509e;
    font-size: 1.2rem;
}

.logo img {
height: 3rem;
}

.body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

.main {
    width: 100vw;
    overflow-x: hidden;
}
.signButton {
    max-width: 200px;
    min-width: 100px;
    margin: .5rem;
}
.refuseButton {
    max-width: 200px;
    min-width: 100px;
    margin: .5rem;
    background-color: rgb(217, 45, 21) !important;
}
.title {
    margin: 1rem;
}

.canvas {
    border: #0f509e solid 2px;
    border-radius: 1rem;
}
.buttons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 8px 0;
}
.wrapper {
    max-width: calc(21cm + 2px);
}
.signatureWrapper {
    max-width: 600px;
    margin-top: 8px;
}

.rotateWrapper {
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #5c5c5f;
}

.rotateWrapper i {
    margin-right: 8px;
}

.padButtonsSmall {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    border-radius: 50px;
    transform: scale(.8);
    background-color: white;
    padding-bottom: 16px;
}

.padButtonsBig {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 8px 0;
}
.signatureWrapperSmall {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
}

.backdrop {
    opacity: 1 !important;
    background: rgba(0 ,0 ,0, 0.4) !important;
    backdrop-filter: blur(5px);
}

.modalAlertDescription {
    padding: 20px 0 30px 0;
}

.errorMessageModal {
    text-align: start;
    margin-bottom: 10px;
    padding: 0 15px;
    color: #A80B12;
}

.unauthorizedContainer {
    margin-top: 2rem;
    text-align: center;
}
